<template>
  <div class="d-lg-none">
    <div
      v-for="row in rows"
      :key="row"
      class="list-group mb-2"
      style="height: 216px"
    >
      <div class="list-group-item h-100"></div>
    </div>
  </div>
  <div class="d-none d-lg-block">
    <div
      v-for="row in rows"
      :key="row"
      class="list-group mb-2"
      style="height: 64px"
    >
      <div class="list-group-item h-100"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "OperationSummarySkeleton",
  setup() {
    return {
      rows: parseInt(process.env.VUE_APP_API_LIMIT),
    };
  },
});
</script>
