<template>
  <template v-if="operations && operations.length">
    <template v-for="(op, idx) in operations" :key="idx">
      <operation-info :operation-details="op" :idx="idx" />
    </template>
  </template>
  <template v-if="operations && operations.length == 0 && !loading">
    <no-content />
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import OperationInfo from "@/components/operations/OperationInfo.vue";
import NoContent from "@/components/NoContent.vue";

export default defineComponent({
  name: "OperationSummary",
  components: {
    OperationInfo,
    NoContent,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const operations = computed(() => {
      switch (route.name) {
        case "TxnDetailsRoute":
          return store.getters["transactions/getSelectedTxn"].operations;
        case "AccountDetailsRoute":
          return store.getters["accounts/getAccount"].operations_r;
        case "LiquidityPoolDetailsRoute":
          return store.getters["liquidity_pools/getLiquidityPoolOperations"];
        case "LedgerDetailsRoute":
          return store.getters["ledgers/getLedgerOperations"];
        default:
          return store.getters["operations/getLatestOperations"];
      }
    });

    const loading = computed(() => {
      switch (route.name) {
        case "LiquidityPoolDetailsRoute":
          return store.getters["liquidity_pools/getLoadingTabStatus"];
        case "LedgerDetailsRoute":
          return store.getters["ledgers/getLoadingTabStatus"];
        default:
          return store.getters["operations/getLoadingStatus"];
      }
    });

    return {
      store,
      loading,
      operations,
    };
  },
});
</script>
