<template>
  <div class="card">
    <div class="card-body text-center py-3">
      <div class="flex-shrink-0 bg-primary bg-opacity-10 rounded d-flex mx-auto mb-2" style="height: 55px; width: 55px;">
        <i class="bi bi-list-check fs-2 rounded-circle align-self-center mx-auto text-body"></i>
      </div>
      <p class="mb-0"><span class="text-body text-uppercase">{{errMsg}}</span></p>
      <p v-if="descriptionMsg" class="small mb-0"><span class="text-muted">{{descriptionMsg}}</span></p>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent} from "vue";
import {useI18n} from "vue-i18n";
export default defineComponent({
  name: "NoContent",
  props: {
    customMsg: String,
    description: String 
  },
  setup(props) {
    const {t} = useI18n();
    const errMsg = computed(() => {
      if (props.customMsg) {
        return props.customMsg;
      }
      return t("error.no_data") 
    });
    const descriptionMsg = computed(() => {
      if (props.description) {
        return props.description;
      }
      return t("error.no_data_description") 
    });
    return {t, errMsg, descriptionMsg};
  },
});
</script>

<style></style>
