<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
    <div class="ms-2">
      <div style="min-width: 180px" class="d-flex justify-content-between">
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('operations_page.enable_data_stream')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            @click="startStream"
            :disabled="streaming"
            :class="{
              'bg-opacity-25': streaming,
              'bg-body': !streaming,
            }"
          >
            <i class="bi bi-play"></i>
          </button>
        </span>
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('operations_page.pause_data_stream')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            @click="pauseStream"
            :disabled="!streaming"
            :class="{
              'bg-opacity-25': !streaming,
              'bg-body': streaming,
            }"
          >
            <i class="bi bi-pause"></i>
          </button>
        </span>
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('operations_page.previous_page')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            @click="prevPage()"
            :disabled="disablePrevBtn"
          >
            <i class="bi bi-arrow-left"></i></button
        ></span>
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('operations_page.next_page')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            @click="nextPage()"
            :disabled="disableNextBtn"
          >
            <i class="bi bi-arrow-right"></i></button
        ></span>
      </div>
    </div>
  </div>
  <operation-summary-skeleton v-if="loading" />
  <error v-else-if="error" />
  <operation-summary v-else />
</template>

<script>
import { defineComponent, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import OperationSummary from "@/components/summaries/OperationSummary.vue";
import Search from "@/components/Search.vue";
import Error from "@/components/Error.vue";
import OperationSummarySkeleton from "@/components/skeletons/summaries/OperationSummarySkeleton.vue";
export default defineComponent({
  name: "Operations",
  components: {
    OperationSummary,
    Search,
    Error,
    OperationSummarySkeleton,
  },
  setup() {
    const store = useStore();
    store.dispatch("operations/startStreaming");
    const nextPage = () => {
      store.dispatch("operations/fetchNextPage");
    };
    const prevPage = () => {
      store.dispatch("operations/fetchPrevPage");
    };
    const startStream = () => {
      store.dispatch("operations/startStreaming");
    };
    const pauseStream = () => {
      store.dispatch("operations/stopStreaming");
    };
    const disablePrevBtn = computed(
      () => store.getters["operations/disablePrevBtn"]
    );
    const disableNextBtn = computed(
      () => store.getters["operations/disableNextBtn"]
    );
    const streaming = computed(() => store.getters["operations/isStreaming"]);
    const loading = computed(
      () => store.getters["operations/getLoadingStatus"]
    );
    const error = computed(() => store.getters["operations/getError"]);
    onUnmounted(pauseStream);
    return {
      nextPage,
      prevPage,
      startStream,
      pauseStream,
      disablePrevBtn,
      disableNextBtn,
      streaming,
      loading,
      error,
    };
  },
});
</script>

    
